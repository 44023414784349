<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Page</Heading>
			</GridContainer>
			<GridContainer size="12 9@lg">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Layout</Heading>
							<Paragraph>The Page is an outer wrapper for the main content of a page. It includes the header, body, footer and optional sidebar.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" align="left">
							<Theme ar dl ed sd wm>
								<div class="dl-page">
									<div class="dl-header">
										<Paragraph margin="none">Header</Paragraph>
									</div>
									<div class="dl-body">
										<Paragraph margin="none">Body</Paragraph>
									</div>
									<div class="dl-footer">
										<Paragraph margin="none">Footer</Paragraph>
									</div>
								</div>
							</Theme>
							<Theme hv>
								<div class="dl-page dl-page--sidebar">
									<div class="dl-sidebar">
										<Paragraph margin="none">Sidebar</Paragraph>
									</div>
									<div class="dl-header">
										<Paragraph margin="none">Header</Paragraph>
									</div>
									<div class="dl-body">
										<Paragraph margin="none">Body</Paragraph>
									</div>
									<div class="dl-footer">
										<Paragraph margin="none">Footer</Paragraph>
									</div>
								</div>
							</Theme>
							<Theme ds>
								<div class="dl-page dl-page--sidebar2">
									<div class="dl-sidebar">
										<Paragraph margin="none">Sidebar</Paragraph>
									</div>
									<div class="dl-header">
										<Paragraph margin="none">Header</Paragraph>
									</div>
									<div class="dl-body">
										<Paragraph margin="none">Body</Paragraph>
									</div>
									<div class="dl-footer">
										<Paragraph margin="none">Footer</Paragraph>
									</div>
								</div>
							</Theme>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Heading size="3">Section</Heading>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Example</Heading>
							<Paragraph>A section represents a standalone section of the body component.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Section size="small" color="light">
								<Stack direction="column" align="left" space="2">
									<Heading size="2">Heading</Heading>
									<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec elementum, massa malesuada hendrerit imperdiet, nulla dui egestas sapien, a bibendum neque lacus eget magna. Sed iaculis mi neque, eu placerat neque vehicula id. Phasellus in libero consectetur, malesuada mauris sed, finibus nunc.</Paragraph>
									<Button label="Button" color="primary" />
								</Stack>
							</Section>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Colors</Heading>
							<Paragraph>There are five color variations for section background colors that can be applied using the color prop.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stage>
							<Section size="small">
								<Heading size="5" margin="none">Default Section</Heading>
							</Section>
							<Section size="small" color="primary">
								<Heading size="5" margin="none">Primary Section</Heading>
							</Section>
							<Section size="small" color="secondary">
								<Heading size="5" margin="none" whiteout>Secondary Section</Heading>
							</Section>
							<Section size="small" color="tertiary">
								<Heading size="5" margin="none" whiteout>Tertiary Section</Heading>
							</Section>
							<Section size="small" color="light">
								<Heading size="5" margin="none">Light Section</Heading>
							</Section>
							<Section size="small" color="dark">
								<Heading size="5" margin="none" whiteout>Dark Section</Heading>
							</Section>
						</Stage>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Sizes</Heading>
							<Paragraph>There are four size variations for sections padding that can be set using the size prop.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Stage>
								<Section size="xsmall" color="light">
									<Heading size="5" margin="none">Xsmall Section</Heading>
								</Section>
							</Stage>
							<Stage>
								<Section size="small" color="light">
									<Heading size="5" margin="none">Small Section</Heading>
								</Section>
							</Stage>
							<Stage>
								<Section color="light">
									<Heading size="5" margin="none">Default Section</Heading>
								</Section>
							</Stage>
							<Stage>
								<Section size="large" color="light">
									<Heading size="5" margin="none">Large Section</Heading>
								</Section>
							</Stage>
							<Stage>
								<Section size="xlarge" color="light">
									<Heading size="5" margin="none">Xlarge Section</Heading>
								</Section>
							</Stage>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Page Properties</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">layout</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										sidebar
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Changes the page styles to incorporate the optional sidebar.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Heading size="5">Section Properties</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">color</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The base color that applies to the section.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the size of the section padding.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">image</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Set the background image.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
			theme: '',
      layout: 'default',
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
	methods: {
		setLayout(e){
			this.layout = e
		},
	}
}
</script>

<style lang="scss" scoped>
.dl-page{
	display: grid;
	width: 100%;
	grid-template-columns: 250px auto;
	grid-template-rows: 90px 180px 90px;
	
	.dl-header{
		border: 3px solid #1c84ee;
		border-bottom: none;
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 2;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-body{
		border: 3px solid #1c84ee;
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 3;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-footer{
		border: 3px solid #1c84ee;
		border-top: none;
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 3;
		grid-row-end: 4;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-sidebar{
		border: 3px solid #1c84ee;
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 2;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.dl-page--sidebar{
	grid-template-columns: 150px auto;
	grid-template-rows: 90px 180px 90px;
	
	.dl-header{
		border: 3px solid #1c84ee;
		border-bottom: none;
		border-left: none;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 2;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-body{
		border: 3px solid #1c84ee;
		border-left: none;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 3;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-footer{
		border: 3px solid #1c84ee;
		border-top: none;
		border-left: none;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 3;
		grid-row-end: 4;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-sidebar{
		border: 3px solid #1c84ee;
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 1;
		grid-row-end: 4;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.dl-page--sidebar2{
	grid-template-columns: 150px auto;
	grid-template-rows: 90px 180px 90px;
	
	.dl-header{
		border: 3px solid #1c84ee;
		border-bottom: none;
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 2;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-body{
		border: 3px solid #1c84ee;
		border-left: none;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 3;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-footer{
		border: 3px solid #1c84ee;
		border-top: none;
		border-left: none;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 3;
		grid-row-end: 4;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dl-sidebar{
		border: 3px solid #1c84ee;
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 2;
		grid-row-end: 4;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
